// Channel events
export const JOBS_CHANNEL_EVENT_JOB_STARTED = "JOB_STARTED";
export const JOBS_CHANNEL_EVENT_JOB_PROGRESS = "JOB_PROGRESS";
export const JOBS_CHANNEL_EVENT_JOB_COMPLETED = "JOB_COMPLETED";

// Channel types
export const JOBS_CHANNEL_TYPE_RECHECK_ALL_VALIDATIONS = "RECHECK_ALL_VALIDATIONS";
export const JOBS_CHANNEL_TYPE_CHECK_PLACEHOLDERS = "CHECK_PLACEHOLDERS";
export const JOBS_CHANNEL_TYPE_IMPORT_VERIFY = "IMPORT_VERIFY";
export const JOBS_CHANNEL_TYPE_IMPORT_IMPORT = "IMPORT_IMPORT";

export interface IJobsChannelEvent {
    event:
        | typeof JOBS_CHANNEL_EVENT_JOB_STARTED
        | typeof JOBS_CHANNEL_EVENT_JOB_PROGRESS
        | typeof JOBS_CHANNEL_EVENT_JOB_COMPLETED;
    type:
        | typeof JOBS_CHANNEL_TYPE_RECHECK_ALL_VALIDATIONS
        | typeof JOBS_CHANNEL_TYPE_CHECK_PLACEHOLDERS
        | typeof JOBS_CHANNEL_TYPE_IMPORT_VERIFY
        | typeof JOBS_CHANNEL_TYPE_IMPORT_IMPORT;
    project_id: string;
    import_id?: string;
}
